/*
 * @Auth: linjituan
 * @Date: 2022-03-14 10:21:14
 * @LastEditors: linjituan
 * @LastEditTime: 2022-03-14 11:24:19
 */
import request from '@/utils/request'
const baseURL = '/rc-api'
// 查询用户微信绑定信息列表
export function listWxUsersBind (query) {
  return request({
    url: '/internal/recyclable/wxUsersBind/list',
    baseURL,
    method: 'get',
    params: query
  })
}

// 查询用户微信绑定信息详细
export function getWxUsersBind (id) {
  return request({
    url: '/internal/recyclable/wxUsersBind/' + id,
    baseURL,
    method: 'get'
  })
}

// 新增用户微信绑定信息
export function addWxUsersBind (data) {
  return request({
    url: '/internal/recyclable/wxUsersBind',
    baseURL,
    method: 'post',
    data: data
  })
}

// 修改用户微信绑定信息
export function updateWxUsersBind (data) {
  return request({
    url: '/internal/recyclable/wxUsersBind',
    baseURL,
    method: 'put',
    data: data
  })
}

// 删除用户微信绑定信息
export function delWxUsersBind (id) {
  return request({
    url: '/internal/recyclable/wxUsersBind/' + id,
    baseURL,
    method: 'delete'
  })
}

// 导出用户微信绑定信息
export function exportWxUsersBind (query) {
  return request({
    url: '/internal/recyclable/wxUsersBind/export',
    baseURL,
    method: 'get',
    params: query
  })
}
