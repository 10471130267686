<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" :title="fromTitle" @close="onClose">
    <a-form-model ref="form" :model="form" :rules="readOnly ? {} : rules">
      <a-form-model-item label="用户账号" prop="userId">
        <a-input :disabled="readOnly" v-model="form.userName" placeholder="请输入用户账号" />
      </a-form-model-item>
      <a-form-model-item label="open_id" prop="openId">
        <a-input :disabled="readOnly" v-model="form.openId" placeholder="请输入open_id" />
      </a-form-model-item>
      <a-form-model-item label="union_id" prop="unionId">
        <a-input :disabled="readOnly" v-model="form.unionId" placeholder="请输入union_id" />
      </a-form-model-item>
      <a-form-model-item label="昵称" prop="nickName">
        <a-input :disabled="readOnly" v-model="form.nickName" placeholder="昵称" />
      </a-form-model-item>
      <a-form-model-item label="绑定标识" prop="bindFlag">
        <a-input :disabled="readOnly" :value="optionsFormat(bindFlagOptions, form.bindFlag)" placeholder="绑定标识" />
        <!-- <a-radio-group v-model="form.bindFlag" button-style="solid">
          <a-radio-button v-for="(item, index) in bindFlagOptions" :key="index" :value="item.dictValue">{{
            item.dictLabel
          }}</a-radio-button>
        </a-radio-group> -->
      </a-form-model-item>
      <!-- <a-form-model-item label="是否删除" prop="status">
        <a-input :disabled="readOnly" :value="optionsFormat(statusOptions, form.status)" placeholder="昵称" />
        <a-radio-group v-model="form.status" button-style="solid">
          <a-radio-button v-for="(item, index) in statusOptions" :key="index" :value="item.dictValue">
            {{ item.dictLabel }}
          </a-radio-button>
        </a-radio-group>
      </a-form-model-item> -->
      <div class="bottom-control" v-if="!readOnly">
        <a-space>
          <a-button type="primary" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getWxUsersBind, addWxUsersBind, updateWxUsersBind } from '@/api/recyclable/wxUsersBind'

export default {
  name: 'CreateForm',
  props: {
    bindFlagOptions: {
      type: Array,
      default: () => []
    },
    statusOptions: {
      type: Array,
      default: () => []
    },
    optionsFormat: {
      type: Function,
      default: () => () => {}
    }
  },
  components: {},
  data() {
    return {
      loading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,
        userId: null,
        openId: null,
        unionId: null,
        nickName: null,
        bindFlag: null,
        status: 0,
        createBy: null,
        createTime: null,
        updateBy: null,
        updateTime: null
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        openId: [{ required: true, message: 'open_id不能为空', trigger: 'blur' }],
        unionId: [{ required: true, message: 'union_id不能为空', trigger: 'blur' }]
      },
      readOnly: true
    }
  },
  filters: {},
  created() {},
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,
        userId: null,
        openId: null,
        unionId: null,
        nickName: null,
        bindFlag: null,
        status: 0,
        createBy: null,
        createTime: null,
        updateBy: null,
        updateTime: null
      }
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getWxUsersBind(id).then(response => {
        this.form = response.data
        this.form.userName = row.userName
        for (const key in this.form) {
          if (typeof this.form[key] === 'number') {
            this.form[key] = this.form[key] + ''
          }
        }
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.id !== undefined && this.form.id !== null) {
            updateWxUsersBind(this.form).then(response => {
              this.$message.success('修改成功', 3)
              this.open = false
              this.$emit('ok')
            })
          } else {
            addWxUsersBind(this.form).then(response => {
              this.$message.success('新增成功', 3)
              this.open = false
              this.$emit('ok')
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
