var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        width: "35%",
        "label-col": 4,
        "wrapper-col": 14,
        visible: _vm.open,
        title: _vm.fromTitle,
      },
      on: { close: _vm.onClose },
    },
    [
      _c(
        "a-form-model",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.readOnly ? {} : _vm.rules },
        },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "用户账号", prop: "userId" } },
            [
              _c("a-input", {
                attrs: {
                  disabled: _vm.readOnly,
                  placeholder: "请输入用户账号",
                },
                model: {
                  value: _vm.form.userName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "userName", $$v)
                  },
                  expression: "form.userName",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "open_id", prop: "openId" } },
            [
              _c("a-input", {
                attrs: { disabled: _vm.readOnly, placeholder: "请输入open_id" },
                model: {
                  value: _vm.form.openId,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "openId", $$v)
                  },
                  expression: "form.openId",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "union_id", prop: "unionId" } },
            [
              _c("a-input", {
                attrs: {
                  disabled: _vm.readOnly,
                  placeholder: "请输入union_id",
                },
                model: {
                  value: _vm.form.unionId,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "unionId", $$v)
                  },
                  expression: "form.unionId",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "昵称", prop: "nickName" } },
            [
              _c("a-input", {
                attrs: { disabled: _vm.readOnly, placeholder: "昵称" },
                model: {
                  value: _vm.form.nickName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "nickName", $$v)
                  },
                  expression: "form.nickName",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "绑定标识", prop: "bindFlag" } },
            [
              _c("a-input", {
                attrs: {
                  disabled: _vm.readOnly,
                  value: _vm.optionsFormat(
                    _vm.bindFlagOptions,
                    _vm.form.bindFlag
                  ),
                  placeholder: "绑定标识",
                },
              }),
            ],
            1
          ),
          !_vm.readOnly
            ? _c(
                "div",
                { staticClass: "bottom-control" },
                [
                  _c(
                    "a-space",
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.submitForm },
                        },
                        [_vm._v(" 保存 ")]
                      ),
                      _c(
                        "a-button",
                        {
                          attrs: { type: "dashed" },
                          on: { click: _vm.cancel },
                        },
                        [_vm._v(" 取消 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }